<template>
  <div class="home">
    <div class="w3l-bootstrap-header fixed-top">
  <nav class="navbar navbar-expand-lg navbar-light p-2">
    <div class="container">
      <a class="navbar-brand" href="/">ECAD</a>
      <!-- if logo is image enable this   
    <a class="navbar-brand" href="#index.html">
        <img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
    </a> -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" href="/">{{ $t("Home.Nav.Home") }}</a>
          </li>
          <!--li class="nav-item">
            <a class="nav-link" href="about.html">About</a>
          </li-->
          <li class="nav-item">
            <a class="nav-link" href="/#/contact">{{ $t("Home.Nav.Contact") }}</a>
          </li>
        </ul>
        <!--div class="form-inline">
          <a href="#login.html" class="login mr-4">Log in</a>
            <a href="#signup.html" class="btn btn-primary btn-theme">Create Free Account</a>
        </div-->
      </div>

      <!-- <div id="fy" style="cursor: pointer;color:rgba(25, 51, 101, 0.5)" @click="changeLang">{{ locales }}</div> -->
    </div>
  </nav>
</div>
<!-- index-block1 -->
<div class="w3l-index-block1">
  <div class="content py-5">
    <div class="container">
      <div class="row align-items-center py-md-5 py-3">
        <div class="col-md-5 content-left pt-md-0 pt-5">
          <h1>{{ $t('Home.Block1.Title') }}</h1>
          <p class="mt-3 mb-md-5 mb-4">{{ $t('Home.Block1.Dec') }}</p>
          <a href="/#/contact" class="btn btn-primary btn-theme">{{ $t('Home.Block1.Button') }}</a>
        </div>
        <div class="col-md-7 content-photo mt-md-0 mt-5">
          <img src="assets/images/main.jpg" class="img-fluid" alt="main image">
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</div>
<!-- //index-block1 -->
<!-- index-block2 -->
<section class="w3l-index-block2 py-5">
  <div class="container py-md-3">
    <div class="heading text-center mx-auto">
      <h3 class="head">{{ $t('Home.Block2.Title') }} </h3>
      <p class="my-3 head"> {{ $t('Home.Block2.Dec') }}</p>
    </div>
    <div class="row bottom_grids pt-md-3">
      <div class="col-lg-4 col-md-6 mt-5">
        <div class="s-block">
          <a href="#blog-single.html" class="d-block p-lg-4 p-3">
            <img src="assets/images/s1.png" alt="" class="img-fluid" />
            <h3 class="my-3">{{ $t('Home.Block2.Grids1.Title') }}</h3>
            <p class="">{{ $t('Home.Block2.Grids1.Dec') }}</p>
          </a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-5">
        <div class="s-block">
          <a href="#blog-single.html" class="d-block p-lg-4 p-3">
            <img src="assets/images/s2.png" alt="" class="img-fluid" />
            <h3 class="my-3">{{ $t('Home.Block2.Grids2.Title') }}</h3>
            <p class="">{{ $t('Home.Block2.Grids2.Dec') }}</p>
          </a>
        </div>
      </div>
      <div class="col-lg-4 mt-5">
        <div class="s-block">
          <a href="#blog-single.html" class="d-block p-lg-4 p-3">
            <img src="assets/images/s3.png" alt="" class="img-fluid" />
            <h3 class="my-3">{{ $t('Home.Block2.Grids3.Title') }}</h3>
            <p class="">{{ $t('Home.Block2.Grids3.Dec') }}</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- /index-block2 -->
<!-- content-with-photo17 -->
<section class="w3l-index-block3">
  <div class="section-info py-5">
    <div class="container py-md-3">
      <div class="row cwp17-two align-items-center">
        <div class="col-md-6 cwp17-image">
          <img src="assets/images/business.png" class="img-fluid" alt="" />
        </div>
        <div class="col-md-6 cwp17-text">
          <h2>{{ $t('Home.Block2.BottomTitle') }}</h2>
          <p>{{ $t('Home.Block2.BottomDec') }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- content-with-photo17 -->
 <div class="w3l-index-block4">
   <div class="features-bg py-5">
     <!-- features15 block -->
     <div class="container py-md-3">
       <div class="heading text-center mx-auto">
         <h3 class="head">{{ $t('Home.Block3.Title') }}</h3>
         <p class="my-3 head"> {{ $t('Home.Block3.Dec') }}</p>
       </div>
       <div class="row">
         <div class="col-md-6 features15-col-text">
           <a href="#url" class="d-flex flex-wrap feature-unit align-items-center">
             <div class="col-sm-3">
               <div class="features15-info">
                 <span class="fa fa-facebook-square" aria-hidden="true"></span>
               </div>
             </div>
             <div class="col-sm-9 mt-sm-0 mt-4">
               <div class="features15-para">
                 <h4>{{ $t('Home.Block3.Grids1.Title') }}</h4>
                 <p>{{ $t('Home.Block3.Grids1.Dec') }}</p>
               </div>
             </div>
           </a>
         </div>
         <div class="col-md-6 features15-col-text">
           <a href="#url" class="d-flex flex-wrap feature-unit align-items-center">
             <div class="col-sm-3">
               <div class="features15-info">
                 <span class="fa fa-video-camera" aria-hidden="true"></span>
               </div>
             </div>
             <div class="col-sm-9 mt-sm-0 mt-4">
               <div class="features15-para">
                 <h4>{{ $t('Home.Block3.Grids4.Title') }}</h4>
                 <p>{{ $t('Home.Block3.Grids4.Dec') }}</p>
               </div>
             </div>
           </a>
         </div>
         <div class="col-md-6 features15-col-text">
           <a href="#url" class="d-flex flex-wrap feature-unit align-items-center">
             <div class="col-sm-3">
               <div class="features15-info">
                 <span class="fa fa-graduation-cap" aria-hidden="true"></span>
               </div>
             </div>
             <div class="col-sm-9 mt-sm-0 mt-4">
               <div class="features15-para">
                 <h4>{{ $t('Home.Block3.Grids2.Title') }}</h4>
                 <p>{{ $t('Home.Block3.Grids2.Dec') }}</p>
               </div>
             </div>
           </a>
         </div>
         <div class="col-md-6 features15-col-text">
           <a href="#url" class="d-flex flex-wrap feature-unit align-items-center">
             <div class="col-sm-3">
               <div class="features15-info">
                 <span class="fa fa-sort-alpha-asc" aria-hidden="true"></span>
               </div>
             </div>
             <div class="col-sm-9 mt-sm-0 mt-4">
               <div class="features15-para">
                 <h4>{{ $t('Home.Block3.Grids5.Title') }}</h4>
                 <p>{{ $t('Home.Block3.Grids5.Dec') }}</p>
               </div>
             </div>
           </a>
         </div>
         <div class="col-md-6 features15-col-text">
           <a href="#url" class="d-flex flex-wrap feature-unit align-items-center">
             <div class="col-sm-3">
               <div class="features15-info">
                 <span class="fa fa-laptop" aria-hidden="true"></span>
               </div>
             </div>
             <div class="col-sm-9 mt-sm-0 mt-4">
               <div class="features15-para">
                 <h4>{{ $t('Home.Block3.Grids3.Title') }}</h4>
                 <p>{{ $t('Home.Block3.Grids3.Dec') }}</p>
               </div>
             </div>
           </a>
         </div>
         <div class="col-md-6 features15-col-text">
           <a href="#url" class="d-flex flex-wrap feature-unit align-items-center">
             <div class="col-sm-3">
               <div class="features15-info">
                 <span class="fa fa-envelope" aria-hidden="true"></span>
               </div>
             </div>
             <div class="col-sm-9 mt-sm-0 mt-4">
               <div class="features15-para">
                 <h4>EDM</h4>
                 <p>{{ $t('Home.Block3.Grids6.Dec') }}</p>
               </div>
             </div>
           </a>
         </div>
       </div>
       <div>
         <!-- features15 block -->
       </div>
     </div>
   </div>
 </div>
<!-- content-with-photo17 -->
<section class="w3l-index-block7 py-5">
  <div class="container py-md-3">
    <div class="row cwp17-two align-items-center">
      <div class="col-md-6 cwp17-text">
        <h2>{{ $t('Home.Block4.Title') }}</h2>
        <p>{{ $t('Home.Block4.Dec') }}</p>
      </div>
      <div class="col-md-6 cwp17-image">
        <img src="assets/images/mobile-app.png" class="img-fluid" alt="" />
      </div>
    </div>
  </div>
</section>
<section class="w3l-index-block10">
  <div class="new-block top-bottom">
    <div class="container">
      <div class="middle-section">
        <!-- <h5>Tagline</h5> -->
        <div class="section-width">
          <h2>{{ $t('Home.Block5.Title') }}</h2>
        </div>
        <div class="link-list-menu">
            <p class="mb-5">{{ $t('Home.Block5.Dec') }}</p>
            <a href="/#/contact" class="btn btn-outline-light btn-more">{{ $t('Home.Block5.Button') }} <span class="fa fa-arrow-right" aria-hidden="true"></span></a>
        </div>
      </div>
    </div>
    </div>
  </section>
  <section class="w3l-market-footer">
        <footer class="footer-28">
          <div class="footer-bg-layer">
            <div class="container py-lg-3">
              <div class="row footer-top-28">
                <div class="col-md-6 footer-list-28 mt-5">
                  <h6 class="footer-title-28">{{ $t('Home.Footer.ContactInformation') }}</h6>
                  <ul>
                    <li>
                      <p><strong>{{ $t('Home.Footer.Address') }}</strong> : {{ $t('Home.Footer.AddressValue') }}</p>
                    </li>
                    <li>
                      <p><strong>{{ $t('Home.Footer.Email') }}</strong> : <a href="mailto:info@ecadgrow.com">info@ecadgrow.com</a></p>
                    </li>
                  </ul>

                  <!--div class="main-social-footer-28 mt-3">
                    <ul class="social-icons">
                      <li class="facebook">
                        <a href="#link" title="Facebook">
                          <span class="fa fa-facebook" aria-hidden="true"></span>
                        </a>
                      </li>
                      <li class="twitter">
                        <a href="#link" title="Twitter">
                          <span class="fa fa-twitter" aria-hidden="true"></span>
                        </a>
                      </li>
                      <li class="dribbble">
                        <a href="#link" title="Dribbble">
                          <span class="fa fa-dribbble" aria-hidden="true"></span>
                        </a>
                      </li>
                      <li class="google">
                        <a href="#link" title="Google">
                          <span class="fa fa-google" aria-hidden="true"></span>
                        </a>
                      </li>
                    </ul>
                  </div-->
                </div>
                <!--div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4 footer-list-28 mt-5">
                      <h6 class="footer-title-28">Company</h6>
                      <ul>
                        <li><a href="about.html">About</a></li>
                        <li><a href="#blog.html">Blog Posts</a></li>
                        <li><a href="#pricing.html">FAQ</a></li>
                        <li><a href="#pricing.html">Pricing</a></li>
                      </ul>
                    </div>
                    <div class="col-md-4 footer-list-28 mt-5">
                      <h6 class="footer-title-28">Support</h6>
                      <ul>
                        <li><a href="contact.html">Contact Us</a></li>
                        <li><a href="#signup.html">Create account</a></li>
                        <li><a href="#learn.html">Learning Center</a></li>
                        <li><a href="#career.html">Team</a></li>
                      </ul>
                    </div>
                    <div class="col-md-4 footer-list-28 mt-5">
                      <h6 class="footer-title-28">Product</h6>
                      <ul>
                        <li><a href="#URL">Market</a></li>
                        <li><a href="#URL">VIP</a></li>
                        <li><a href="#URL">Resources</a></li>
                        <li><a href="#URL">Sale</a></li>
                        <li><a href="#URL">Admin UI</a></li>
                      </ul>
                    </div>
                  </div>
                </div-->
              </div>
            </div>


            <div class="midd-footer-28 align-center py-lg-4 py-3 mt-5">
              <div class="container">
                <p class="copy-footer-28 text-center"> &copy; 2021 ECAD INC. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </footer>
         <!-- move top -->
        <button @click="topFunction()" id="movetop" title="Go to top">
          &#10548;
        </button>
  </section>
  
  </div>
</template>

<script>
import "../../public/assets/js/bootstrap.min.js";
import "../../public/assets/js/jquery-3.3.1.min";
import "../../public/assets/js/owl.carousel";
export default {
  name: 'Home',
  components: {
  },
  watch: {
    
  },
  mounted() {
    // window.onscroll = function () {
    //     this.scrollFunction()
    // };
    window.addEventListener("scroll", () => {
      this.scrollFunction()
    })
  },
  computed: {
      locales() {
      if (this.$i18n.locale == "tw") {
        return "繁體";
      }else {
        return "English";
      }
    },
  },
  methods: {
    scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        document.getElementById("movetop").style.display = "block";
      } else {
        document.getElementById("movetop").style.display = "none";
      }
    },
    changeLang() {
      if (this.lang === "tw") {
        this.lang = "en";
        // this.langs = "English"
        this.$i18n.locale = this.lang;
        localStorage.setItem('lang', this.lang);
      } else {
        this.lang = "tw";
        // this.langs = "繁体"
        this.$i18n.locale = this.lang;
        localStorage.setItem('lang', this.lang);
      }
      
    },

    // When the user clicks on the button, scroll to the top of the document
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  }
}
</script>
<style scoped>
@import "../../public/assets/css/style-starter.css";
/* @import "//fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap"; */
/* @import "//fonts.googleapis.com/css?family=Nunito:200,300,400,600,700,800,900&display=swap"; */
</style>
