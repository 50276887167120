/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2022-12-08 14:22:06
 * @LastEditors: 
 * @LastEditTime: 2022-12-08 14:29:26
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery'
import store from './store'
import "../src/common/index.css"
import VueI18n from 'vue-i18n'
import en from "./utils/lang/en"
import tw from "./utils/lang/tw"
Vue.config.productionTip = false
Vue.use(VueI18n)
const i18n = new VueI18n({
  // locale: localStorage.getItem('lang') || 'en', // 语言标识
  locale: 'en',
  messages: {
    en: en,
    tw: tw
  }
})

new Vue({
  router,
  $,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
