
export default {
    Home: {
        // 导航栏
        Nav: {
            Home: "Home",
            Contact: 'Contact',
        },
        // index-block1
        Block1: {
            Title: 'Meet with us to build your social media marketing',
            Dec: 'It is vital to have a strong online presence and social strategy, by partnering with us, we can give you the tools you need to increase your online visibility, stand out from the crowd and grow your business.',
            Button: 'CONTACT US'
        },
        // index-block2
        Block2: {
            Title: 'We provide solutions to grow your business',
            Dec: 'If you do not currently have a social presence, currently managing your business in-house or are looking for a team to take over online marketing, we have solutions for you.',
            // grids
            Grids1: {
                Title: 'Digital Marketing',
                Dec: 'Start or improve your digital marketing including social media, search engine, KOL & Influencers, push your business precisely to the clients!',
            },
            Grids2: {
                Title: 'Advertising',
                Dec: 'Comprehensive ADS solutions for multi platforms and advertising strategy, reduce promotion costs and make high benifits for you.',
            },
            Grids3: {
                Title: 'Excellent Tools',
                Dec: 'Start your efficent operation with our excellent tools including media account management(Facebook, Messenger, TikTok, etc), EDM, landing page, chatting robot, etc.',
            },
            BottomTitle: 'Start your business easily with Us',
            BottomDec: 'Tell us your business needs, and our experienced professional marketing consultants will provide you with exclusive marketing strategies .',
        },
        // index-block3
        Block3: {
            Title: 'Social media with professional content',
            Dec: '3 out of 4 consumers consult Social Media before buying a product or service. With the fast changing Social Media Landscape, we help our clients to produce engaging contents with top of the industry conversion rate. The social media marketing channels we covered includes:',
            Grids1: {
                Title: 'Facebook Advertising and Management',
                Dec: 'Automate your Facebook ad strategies to boost ROAS with our optimization tools.'
            },
            Grids2: {
                Title: 'KOL Analysis',
                Dec: 'Identify appropriate KOLs who are suitable to promote your brands, products,campaigns at a glance.'
            },
            Grids3: {
                Title: 'Customize Brand Page',
                Dec: 'Easy to customize your brand page with our web page design tools in a few minutes.'
            },
            Grids4: {
                Title: 'Video Advertising',
                Dec: 'Rich industry video advertising materials, and multi - account management tools for TikTok.'
            },
            Grids5: {
                Title: 'Competitor Analysis',
                Dec: 'Multi-dimensional analysis of marketing data and ranking of competitors in the same industry.'
            },
            Grids6: {
                Title: 'EDM',
                Dec: 'Directly contact to end customers and manage emails with our EDM.'
            },
        },
        // index-block4
        Block4: {
            Title: '500%+ AVG ROI',
            Dec: 'We achieves high ROI social marketing with data driven strategies, perfecting your marketing while you plan about dealing with exponentially more clients. We will take care of fine-tuning and performing your ad research, planning, execution, and evaluation.'
        },
        // index-block5
        Block5: {
            Title: 'Start your business marketing with our professional services.',
            Dec: 'Get your exclusive marketing plan and pricing, and start your business growth journey.',
            Button: 'Contact Us'
        },
        // Footer
        Footer: {
            ContactInformation: 'Contact information',
            Address: 'Address',
            AddressValue: '10611 HARWIN DR SUITE 406 HOUSTON TX 77036',
            Email: 'Email'
        }
    },
    // Contact
    Contact: {
        // 导航栏
        Nav: {
            Home: "Home",
            Contact: 'Contact',
        },
        ContactUs: 'Contact Us',
        AddressValue: '10611 HARWIN DR SUITE 406 HOUSTON TX 77036',
        Form: {
            FirstName: 'First Name',
            LastName: 'Last Name',
            YourEmailID: 'Your Email ID',
            PhoneNumber: 'Phone Number',
            Subject: 'Subject',
            Message: 'Message',
            SendMessage: 'Send Message'
        },
        // Footer
        Footer: {
            ContactInformation: 'Contact information',
            Address: 'Address',
            AddressValue: '10611 HARWIN DR SUITE 406 HOUSTON TX 77036',
            Email: 'Email'
        }
    }

}